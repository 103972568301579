
.filter-sheet {
    position: relative;
    &::before {
        content: "";
        position: absolute;
        background-color: inherit;
        height: 100%;
        width: 100vw;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        z-index: -1;
    }
}
