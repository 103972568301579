
.v-btn.filter-button {
    border-radius: 999px !important;
    padding: 0 18px !important;
    border: 1px solid;
    border-color: var(--v-grey-lighten3) !important;
    .v-icon {
        font-size: 24px;
    }
    &::before {
        display: none;
    }
}
.filter-menu {
    ::v-deep {
        .v-toolbar {
            &,
            &__content {
                height: auto !important;
            }
            &__content {
                padding: 4px 12px;
            }
        }
    }
    .v-card__text {
        padding: 24px 18px 18px;
    }
}
@media (min-width: 768px) {
    .filter-button {
        padding: 0 24px !important;
    }
}
