
.v-btn-toggle {
    margin: -6px;
    flex-wrap: wrap;
    justify-content: center;
    .v-btn {
        height: auto !important;
        border: none !important;
        background-color: transparent !important;
        min-width: 0 !important;
        opacity: 1;
        margin: 6px;
        .v-avatar {
            position: relative;
            overflow: visible;
            .v-icon {
                opacity: 0;
            }
        }
        &::before {
            display: none;
        }

        &--active {
            .v-avatar {
                .v-icon {
                    opacity: 1;
                }
            }
        }
    }
}
